import React, { useCallback } from 'react';
import classnames from 'classnames';

import { useRouter } from 'next/router';

import { useTranslation, Trans } from 'src/i18n';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import DotsMoreIcon from 'components/Globals/Layout/Header/Shared/Icons/DotsMoreIcon';
import Link from 'components/uiLibrary/Link'; // TODO: Change to use LinkButton
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import CollapsableList, { CollapsedContainerWrapper } from 'components/uiLibrary/CollapsableList/CollapsableList';
import useTracking from 'components/Globals/Analytics';
import { GOOGLE_OLD_TRACKING_SERVICES } from 'components/Globals/Analytics/constants';

import { TRACK_EVENTS, HEADER_NAV_IDS_URL_MAPPING, GLOBAL_HEADER_LINKS } from 'utils/tracking';
import usePermissions from 'utils/permissions';
import usePageContext from 'utils/hooks/usePageContext';
import { useUserData } from 'utils/hooks/useAuthenticatedUser';
import { useDialogs } from 'utils/hooks/useDialogs';

import { navigationStaticLinks, navLinksMain, navLinksMore } from 'constants/header';
import { TP, ENTITY_TYPE, BASE_PAGE_ROUTES } from 'constants/index';

import classes from './HeaderNavigation.module.scss';

export const HeaderNavigation = ({ autoHideNavbar, isLoggedIn, isStatic, trackingData }) => {
  const Router = useRouter();
  const { basePath, entityType, organizationType, entity, isGlobalNavigationVisible } = usePageContext();
  const { t } = useTranslation('NS_APP_GLOBALS');
  const track = useTracking();
  const { isAdmin } = usePermissions();
  const userData = useUserData();
  const { setIsOpenSetupPassword } = useDialogs();
  const isScrolled = useScrollTrigger({ disableHysteresis: true, threshold: 50 });
  const navLinks = isLoggedIn ? [navigationStaticLinks[0]] : navigationStaticLinks;
  const query = Router?.query?.query;

  const isActiveLink = useCallback(
    link => {
      const isHome = !basePath && link.as === BASE_PAGE_ROUTES.HOME && !entityType;
      const basePathMatches = basePath === link?.path;
      const isArtistIndex = entityType === ENTITY_TYPE.ARTIST && link.as === BASE_PAGE_ROUTES.ARTISTS;
      const isOrgIndex =
        entityType === ENTITY_TYPE.ORGANIZATION && !organizationType && link.as === BASE_PAGE_ROUTES.ORGANIZATIONS;
      const isActiveOrgTypeIndex =
        entityType === ENTITY_TYPE.ORGANIZATION &&
        organizationType &&
        link.as?.startsWith(BASE_PAGE_ROUTES.ORGANIZATIONS) &&
        link.as?.includes(organizationType?.slug);

      return isHome || basePathMatches || isArtistIndex || isOrgIndex || isActiveOrgTypeIndex;
    },
    [basePath, entityType, organizationType],
  );

  return (
    <nav
      className={classnames(classes.navigation, {
        [classes.hideBehind]: isScrolled && autoHideNavbar,
        [classes.static]: isStatic,
        [classes.productionNav]: entityType === ENTITY_TYPE.PRODUCTION && entity,
        [classes.hideNavigation]: !isGlobalNavigationVisible,
      })}
    >
      <div className={classes.navItems}>
        <CollapsableList>
          {navLinksMain.map((link, index) => (
            <Link
              className={classnames(classes.navLink, {
                [classes.navLinkCurrent]: isActiveLink(link),
              })}
              href={query && link.searchPath ? `${link.searchPath}?query=${query}` : link.path}
              {...(link.as && { as: link.as })}
              {...(query && link.asSearchPath && { as: link.asSearchPath })}
              key={`mlink_${index}_${link.path}`}
              disableUnderline
              disableHover
              onClick={() => {
                track.click(
                  {
                    name: TRACK_EVENTS.CLICK,
                    data: {
                      id: HEADER_NAV_IDS_URL_MAPPING[link.path],
                    },
                  },
                  GOOGLE_OLD_TRACKING_SERVICES,
                );
              }}
              trackingData={{
                ...trackingData,
                meta: {
                  path: link.as || link.path,
                },
              }}
            >
              {t(link.title)}
            </Link>
          ))}
          <CollapsedContainerWrapper>
            {({ collapsedItems }) => (
              <div className={`${classes.moreLinksAnchor}`}>
                <span className={classes.navLink}>
                  {t(`${TP}.MORE`)} <DotsMoreIcon />
                </span>
                <div className={classes.moreLinksContainer}>
                  {navLinksMore.map((link, index) => (
                    <Link
                      className={classes.moreLink}
                      href={query && link.searchPath ? `${link.searchPath}?query=${query}` : link.path}
                      {...(query && link.asSearchPath && { as: link.asSearchPath })}
                      {...(!query && link.as && { as: link.as })}
                      key={index}
                      disableUnderline
                      disableHover
                      trackingData={{
                        ...trackingData,
                        meta: {
                          path: link.as || link.path,
                        },
                      }}
                    >
                      {t(link.title)}
                    </Link>
                  ))}
                  {collapsedItems}
                </div>
              </div>
            )}
          </CollapsedContainerWrapper>
        </CollapsableList>

        <div className={classes.staticLinksContainer}>
          {navLinks.map((link, index) => (
            <Link
              className={classnames(classes.staticLink, {
                [classes.isSelected]: link?.type
                  ? link.type.includes(Router?.query?.type)
                  : link.path === Router?.route,
              })}
              href={link.path}
              key={`link_${index}_${link.path}`}
              disableUnderline
              leftIcon={link.icon}
              disableHover
              iconStyle={classnames(classes.staticLinksContainer__linkIcon, {
                [classes.activeIcon]: link?.type
                  ? link.type.includes(Router?.query?.type)
                  : link.path === Router?.route,
              })}
              trackingData={{
                ...trackingData,
                meta: {
                  path: link.as || link.path,
                },
              }}
              onClick={() => {
                track.click(
                  {
                    name: TRACK_EVENTS.CLICK,
                    data: {
                      id:
                        link?.path?.id === 'industry'
                          ? GLOBAL_HEADER_LINKS.GLOBAL_HEADER_INDUSTRY_PROFESSION_LINK
                          : HEADER_NAV_IDS_URL_MAPPING[link?.path],
                    },
                  },
                  GOOGLE_OLD_TRACKING_SERVICES,
                );
              }}
            >
              <Typography size={14} weight="medium" className={classes.staticLinksContainer__link}>
                <Trans
                  ns="NS_APP_GLOBALS"
                  i18nKey={`${TP}.${link.label}`}
                  components={{
                    strong: <Typography weight="bold" />,
                  }}
                />
              </Typography>
            </Link>
          ))}
          {isLoggedIn && (
            <div className={classes.userEmail}>
              <Typography size={14} weight="medium" className={classes.userEmail__text}>
                {userData?.passwordSetupRequired
                  ? userData?.email
                  : t(`${TP}.FN_SHOW_EMAIL_TOP_NAV`, { emailId: userData?.email })}
              </Typography>
              {isAdmin && (
                <Typography size={12} weight="bold">
                  (Admin)
                </Typography>
              )}
            </div>
          )}
          {userData?.passwordSetupRequired && (
            <Typography
              size={14}
              weight="medium"
              className={classes.setupPassword}
              color="secondary"
              onClick={() => setIsOpenSetupPassword(true)}
            >
              <SpriteIcon size="14" icon="lock_black_24dp" className={classes.setupPassword__icon} />
              {t(`${TP}.FN_SETUP_PASSWORD`)}
            </Typography>
          )}
        </div>
      </div>
    </nav>
  );
};

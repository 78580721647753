import React from 'react';
import classnames from 'classnames';
import dynamic from 'next/dynamic';

import useNewTracking from 'components/Globals/Analytics';
import { GOOGLE_OLD_TRACKING_SERVICES, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';
import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';

import { GENERAL_QUERY_LINKS, TP } from 'constants/index';
import { navLinksMain, navLinksMore, featuredNavLinks } from 'constants/header';
import route from 'constants/routes';
import { TRACK_EVENTS, HEADER_NAV_IDS_URL_MAPPING, GLOBAL_HEADER_LINKS } from 'utils/tracking';
import { getBlocksLink } from 'utils/globals/index';
import { useDialogs } from 'utils/hooks/useDialogs';
import { useTranslation } from 'src/i18n';

import classes from './HamburgerMenuContent.module.scss';

const AdvancedSearch = dynamic(() => import('components/Search/AdvancedSearch'));

const HamburgerMenuContent = ({ onClose, userData, trackingData = {} }) => {
  const track = useNewTracking();
  const { t } = useTranslation(['NS_DISPLAY_V4']);
  const { setIsOpenSetupPassword } = useDialogs();

  const googleAnaltyicsHandler = linkPath => {
    track.click(
      {
        name: TRACK_EVENTS.CLICK,
        data: {
          id: HEADER_NAV_IDS_URL_MAPPING[linkPath],
        },
      },
      GOOGLE_OLD_TRACKING_SERVICES,
    );
  };
  return (
    <div className={classes.content}>
      <div className={classes.searchBlock}>
        <AdvancedSearch onClose={onClose} trackingData={trackingData} />
      </div>
      <div className={classnames(classes.menusContainer, classes.featuredLinksBlock)}>
        <Typography size="12" weight="medium">
          {t(`${TP}.FEATURED_FOR_YOU`)}
        </Typography>
        {featuredNavLinks.map((link, index) => (
          <LinkButton
            variant="tertiary"
            rightIcon={<SpriteIcon icon="chevron_right" size={20} />}
            styles={{ root: classes.featuredMenu }}
            href={link.path}
            {...(link.as && { as: link.as })}
            key={`feature_${index}`}
            isLink
            onClick={() => googleAnaltyicsHandler(link.path)}
            trackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.NAVIGATE_CTA, meta: { link: link.path } }}
          >
            <Typography size="14" weight="medium">
              {t(link.title)}
            </Typography>
          </LinkButton>
        ))}
      </div>
      <div className={classes.proLinkBlock}>
        <LinkButton
          variant="tertiary"
          leftIcon={<SpriteIcon icon="dots" size={14} />}
          rightIcon={<SpriteIcon icon="chevron_right" size={20} />}
          styles={{ root: classes.link }}
          href={route.INTRO_AOS}
          disableUnderline
          isLink
          onClick={() => googleAnaltyicsHandler('/pro/companies')}
          trackingData={{
            ...trackingData,
            subComponent: SUB_COMPONENTS.NAVIGATE_CTA,
            meta: { link: '/pro/companies' },
          }}
        >
          <Typography weight="medium">{t(`${TP}.FN_PRO_TOOL`)}</Typography>
          <Typography size="12" color="secondary">
            &nbsp;({t(`${TP}.PRO_PAGE_INDUSTRY`)})
          </Typography>
        </LinkButton>
      </div>
      {userData?.passwordSetupRequired && (
        <div className={classes.proLinkBlock}>
          <LinkButton
            variant="tertiary"
            leftIcon={<SpriteIcon icon="lock_black_24dp" size={14} />}
            rightIcon={<SpriteIcon icon="chevron_right" size={20} />}
            styles={{ root: classes.link }}
            href={route.INTRO_AOS}
            disableUnderline
            onClick={() => {
              onClose();
              setIsOpenSetupPassword(true);
              googleAnaltyicsHandler('/setup-password');
            }}
            trackingData={{
              ...trackingData,
              subComponent: SUB_COMPONENTS.NAVIGATE_CTA,
              meta: { link: '/setup-password' },
            }}
          >
            <Typography weight="medium">{t(`${TP}.FN_SETUP_PASSWORD`)}</Typography>
          </LinkButton>
        </div>
      )}
      <div className={classes.menusContainer}>
        {[...navLinksMain, ...navLinksMore].map((link, index) => (
          <LinkButton
            variant="tertiary"
            rightIcon={<SpriteIcon icon="chevron_right" size={20} />}
            styles={{ root: classes.menu }}
            href={link.path}
            {...(link.as && { as: link.as })}
            key={`mlink_${index}`}
            isLink
            onClick={() => googleAnaltyicsHandler(link.path)}
            trackingData={{
              ...trackingData,
              subComponent: SUB_COMPONENTS.NAVIGATE_CTA,
              meta: { link: link.path },
            }}
          >
            <Typography size="14" weight="medium">
              {t(link.title)}
            </Typography>
          </LinkButton>
        ))}
      </div>
      <div>
        <LinkButton
          variant="tertiary"
          styles={{ root: classes.footerMenu }}
          href="/intro"
          isLink
          trackingData={{
            ...trackingData,
            subComponent: SUB_COMPONENTS.NAVIGATE_CTA,
            meta: { link: '/intro' },
          }}
          onClick={() => {
            track.click(
              {
                name: TRACK_EVENTS.CLICK,
                data: {
                  id: GLOBAL_HEADER_LINKS.GLOBAL_HEADER_ABOUT_OPERA_BASE,
                },
              },
              GOOGLE_OLD_TRACKING_SERVICES,
            );
          }}
        >
          <Typography size={12}>{t(`${TP}.FN_ABOUT_OPEARA`)}</Typography>
        </LinkButton>
        <LinkButton
          variant="tertiary"
          styles={{ root: classes.footerMenu }}
          href="/contact"
          isLink
          trackingData={{
            ...trackingData,
            subComponent: SUB_COMPONENTS.NAVIGATE_CTA,
            meta: { link: '/contact' },
          }}
        >
          <Typography size={12}>{t(`${TP}.m_CONTACTUS`)}</Typography>
        </LinkButton>
        <LinkButton
          variant="tertiary"
          styles={{ root: classes.footerMenu }}
          href={getBlocksLink(GENERAL_QUERY_LINKS)}
          isLink
          external
          trackingData={{
            ...trackingData,
            subComponent: SUB_COMPONENTS.NAVIGATE_CTA,
            meta: { link: getBlocksLink(GENERAL_QUERY_LINKS) },
          }}
        >
          <Typography size={12}>{t(`${TP}.FN_FAQS`)}</Typography>
        </LinkButton>
      </div>
    </div>
  );
};

export default HamburgerMenuContent;

import React from 'react';
import routesWithTitle from 'constants/routesWithTitle';
import DotsMenuIcon from 'components/Globals/Layout/Header/Shared/Icons/DotsMenuIcon';
import routes from 'constants/routes';

export const HIDE_NAVIGATION_ROUTES = [
  '/artists/[id]/[[...action]]',
  '/companies/[country]/[id]/[[...action]]',
  '/search',
];
export const navLinksMain = [
  routesWithTitle.HOME,
  routesWithTitle.PRODUCTIONS,
  routesWithTitle.ARTISTS,
  routesWithTitle.COMPANIES,
  routesWithTitle.FESTIVALS,
  routesWithTitle.MANAGERS,
  routesWithTitle.VENUES,
];

export const navLinksMore = [
  routesWithTitle.ORGANIZATIONS,
  routesWithTitle.SEASONS,
  routesWithTitle.COMPETITIONS,
  routesWithTitle.COMPOSERS,
  routesWithTitle.WORKS,
  { ...routesWithTitle.VIDEOS, title: 'operabase.general.OPERA_ONLINE' },
  // routesWithTitle.HIGHLIGHTS, Not part of phase 1 release
  // routesWithTitle.NEW_RARE, Not part of phase 1 release
];

export const featuredNavLinks = [
  routesWithTitle.INTRO_AUDIENCE,
  routesWithTitle.INDUSTRY_PROFESSION,
  routesWithTitle.CASTING_TOOL,
  routesWithTitle.PRODUCTIONS,
  routesWithTitle.ARTISTS,
  routesWithTitle.MANAGERS,
];

export const headerStaticLinks = [routesWithTitle.CASTING_TOOL, routesWithTitle.RENTALS];
export const navigationStaticLinks = [
  {
    id: 'pro',
    path: routes.INTRO_ALL,
    type: ['companies', 'all'],
    label: 'FN_PRO_TOOL',
    icon: <DotsMenuIcon />,
  },
  {
    id: 'industry',
    type: 'artists',
    path: routes.INTRO_AOS,
    label: 'PRO_PAGE_INDUSTRY',
  },
  {
    id: 'audience',
    path: routes.PRO_AUDIENCE,
    label: 'PROAUDIENCE',
  },
];
